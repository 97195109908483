$font: "Migrena_Grotesque";

// Default colors
$primary: #e2001a;
$secondary: #fff;
$tippy-color: #707173;

$hover: #f9f9f9;
$secondary-journey: #888;

$text: #58585a;
$error: #ff0000;
$warning: #ef662f;
$info: #43b77a;

// Borders
$border-white-xs: rgba(255, 255, 255, 0.2);
$border-xs: rgba(0, 0, 0, 0.08);
$border-m: rgba(0, 0, 0, 0.15);

// Shadows
$shadow-xxs: rgba(0, 0, 0, 0.02);
$shadow-xs: rgba(0, 0, 0, 0.15);
$shadow-m: rgba(0, 0, 0, 0.3);
$shadow-xl: rgba(0, 0, 0, 0.4);

// Breakpoints CSS
$break-mobile: 600px;
$break-small-small: 360px;

$grid-size: 4;
$grid-size-mobile: 4;
$grid-size-small-mobile: 3;

$line-image-height: 20px;
$line-with-border: true;

$override-styles: false;

$board-mobile-margin-top: 80px;
$board-mobile-translateY-offset: 160px;
$board-mobile-default-top: 220px;

$header-height: 0px;
$header-height-mobile: 0px;

$library-url: "";

$start-pin-width: 50;
$start-pin-height: 36;
$end-pin-width: 50;
$end-pin-height: 36;
