$override-styles: true;
@mixin override {
  font-size: 1.125em;

  .lc-journeys {
    .lc-journey:nth-child(2) {
      margin: 0 0 !important;
      border-top-left-radius: 0 !important;
    }
  }

  .lc-transport-title {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    background: #282634 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    opacity: 1;
    text-align: center;
    text-transform: uppercase;
    color: #f4f4fa;
    padding: 0 10px;
    margin-top: 15px;
    font-weight: normal !important;
    font-size: 14px !important;
  }

  .lc-journey-time {
    &.lc-has-taxi-infos {
      border-right: none !important;
    }
  }

  .lc-journey-sections-with-taxi-infos {
    padding: 20px 50px 20px 15px;
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    min-height: 50px;
  }

  .lc-has-taxi-infos {
    padding: 0 10px !important;
  }

  .lc-journey-taxi-compagnies {
    .lc-journey-taxi-compagny {
      width: 100%;
      padding: 5px 0;
      text-transform: uppercase;
      margin-left: 10px;

      .lc-taxi-price {
        margin-left: 10px;
        font-size: 0.7em;
      }
    }
  }

  .lc-taxis-buttons,
  .lc-plans-buttons {
    box-shadow: 0px 3px 15px #00000000;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 15px 0 0;

    .lc-taxi-button,
    .lc-plan-button {
      background-color: #e9252f;
      border-radius: 3px;
      padding: 5px 10px 7px;
      color: #fff;
      font-size: 0.9em;
      text-align: center;
    }
  }

  .lc-taxis-buttons {
    margin: 0 0 20px;

    .lc-taxi-button {
      flex: 1;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .lc-dot {
    height: 5px;
    width: 5px;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
  }
}
